import styled from '@emotion/styled'
import { Accordion } from 'app/components/Common/Accordion'
import { Button } from 'app/components/Common/Button'
import { Lines } from 'app/components/Common/Lines'
import { useVocabularyData } from 'app/utils/vocabulary'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/all'
import React, { memo } from 'react'
gsap.registerPlugin(ScrollToPlugin)

export interface Props {
  languageCode: string
  jobPositions?: any
}

export const JobPositionsList = memo(function JobPositionsList({
  jobPositions,
  languageCode,
}: Props) {
  return (
    <Container>
      <Lines />
      {jobPositions && jobPositions.length > 1 ? (
        <WrapperAccordion>
          <Accordion
            items={jobPositions}
            isJob={true}
            languageCode={languageCode}
          />
        </WrapperAccordion>
      ) : (
        <WrapperNoJob>
          <Title
            dangerouslySetInnerHTML={{
              __html: useVocabularyData('no-job-available', languageCode),
            }}
          />
          <StyledButton
            variant="outline-dark"
            label={useVocabularyData('job-apply-button', languageCode)}
            onClick={() => {
              gsap.to(window, {
                duration: 1,
                ease: 'circ.inOut',
                scrollTo: '#form--job-apply',
              })
              return false
            }}
          />
        </WrapperNoJob>
      )}
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  width: 100%;
  padding-bottom: 5vw;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
`
const WrapperAccordion = styled.div`
  width: 72%;
  margin: 0 auto;
  text-align: center;

  @media (max-width: 991px) {
    width: 100%;
    padding: 30px;
  }
`

const WrapperNoJob = styled.div`
  width: 72%;
  margin: 0 auto;
  text-align: center;
  padding: 5vw;
  background: ${({ theme }) => theme.colors.variants.neutralLight5};

  @media (max-width: 991px) {
    padding: 30px;
  }
`

const Title = styled.h2`
  font-size: 2.2vw;
  margin-bottom: 2.5vw;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fontFamily.heading};

  @media (max-width: 991px) {
    font-size: 20px;
    margin-bottom: 30px;
  }
`

const StyledButton = styled(Button)`
  margin: 0vw auto;
`
