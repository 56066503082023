import axios, { AxiosError } from 'axios'
import { Either, left, right } from 'fp-ts/Either'

interface FileProps extends File {
  buffer: any
  filename_download: string
  originalname: string
  mimytype: string
}

export interface FormData {
  name: string
  lastname: string
  email: string
  phone?: string
  attachment?: FileProps
  message?: string
  privacy_policy: boolean
}

export interface JobPositionFormSenderBackendConfiguration {
  formURL: string
}

export type JobPositionFormSenderBackend = (
  data: FormData,
) => Promise<Either<AxiosError, void>>

export const JobPositionFormSenderBackend =
  ({
    formURL,
  }: JobPositionFormSenderBackendConfiguration): JobPositionFormSenderBackend =>
  async (data): Promise<Either<AxiosError, void>> => {
    const formData = new FormData()

    formData.append('name', data.name)
    formData.append('lastname', data.lastname)
    formData.append('email', data.email)
    if (data.phone) {
      formData.append('phone', data.phone)
    }
    if (data.message) {
      formData.append('message', data.message)
    }
    formData.append('privacy_policy', data.privacy_policy.toString())

    if (data.attachment) {
      formData.append('file', data.attachment)
    }

    try {
      await axios({
        method: 'POST',
        url: formURL,
        headers: {
          'Content-type': 'application/json',
        },
        data: formData,
      })
      return right(undefined)
    } catch (error) {
      return left(error as any)
    }
  }
